<script>
// eslint-disable-next-line
import MetisMenu from "metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'Menu': 'Menu',
      'Dashboard': 'Painel',
      'Register': 'Cadastrar',
      'Financial': 'Financeiro',
      'Awards': 'Premiação',
      'Donations': 'Doações',
      'Donate': 'Doar',
      'Extract': 'Extrato',
      'Products': 'Produtos',
      'Network': 'Rede',
      'Profit': 'Rendimento',
      'Donation': 'Doação',
      'Balance': 'Saldo',
      'Voucher': 'Voucher',
      'Transfer': 'Transferir',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
      'Sponsored': 'Indicados',
      'Team': 'Equipe',
      'Reports': 'Relatórios',
      'Bonus Referral': 'Bônus Indicação',
      'My Donations': 'Minhas Doações',
      'Support': 'Suporte',
      'Downloads': 'Downloads',
      'FAQ': 'FAQ',
      'Tickets': 'Tickets',
      'Invoices': 'Notas Fiscais',
      'My Invoices': 'Minhas Notas',
      'Listing': 'Lista',
      'Send': 'Enviar',
    },
    es: {
      'Menu': 'Menu',
      'Dashboard': 'Panel',
      'Register': 'Registro',
      'Financial': 'Financiero',
      'Awards': 'Premios',
      'Donations': 'Donaciones',
      'Donate': 'Donar',
      'Extract': 'Extracto',
      'Products': 'Productos',
      'Network': 'Red',
      'Profit': 'Rendimiento',
      'Donation': 'Donación',
      'Balance': 'Saldo',
      'Voucher': 'Cupón',
      'Transfer': 'Transferir',
      'Withdrawal': 'Retirada',
      'Withdrawal History': 'Historia de Retirada',
      'Sponsored': 'Patrocinados',
      'Team': 'Equipo',
      'Reports': 'Informes',
      'Bonus Referral': 'Bonus Patrocinio',
      'My Donations': 'Mis Donaciones',
      'Support': 'Soporte',
      'Downloads': 'Descargas',
      'FAQ': 'FAQ',
      'Tickets': 'Tickets',
      'Invoices': 'Facturas',
      'My Invoices': 'Mis facturas',
      'Send': 'Enviar',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  }
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span>{{ t('Dashboard') }}</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z">
            </path>
            <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
          </svg>
          <span class="notranslate">Notas Fiscais</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/invoices/create" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Send") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/invoices" class="side-nav-link-ref">
              <span class="notranslate">{{ t("My Invoices") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M20 7h-1.209A4.92 4.92 0 0 0 19 5.5C19 3.57 17.43 2 15.5 2c-1.622 0-2.705 1.482-3.404 3.085C11.407 3.57 10.269 2 8.5 2 6.57 2 5 3.57 5 5.5c0 .596.079 1.089.209 1.5H4c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zm-4.5-3c.827 0 1.5.673 1.5 1.5C17 7 16.374 7 16 7h-2.478c.511-1.576 1.253-3 1.978-3zM7 5.5C7 4.673 7.673 4 8.5 4c.888 0 1.714 1.525 2.198 3H8c-.374 0-1 0-1-1.5zM4 9h7v2H4V9zm2 11v-7h5v7H6zm12 0h-5v-7h5v7zm-5-9V9.085L13.017 9H20l.001 2H13z">
            </path>
          </svg>
          <span class="notranslate">Sorteios</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/draws/numbers" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Números da Sorte") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 15c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92 0-1.12-.52-3-4-3-2 0-2-.63-2-1s.7-1 2-1 1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3 2 0 2 .68 2 1s-.62 1-2 1z">
            </path>
            <path d="M5 2H2v2h2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4h2V2H5zm13 18H6V4h12z"></path>
          </svg>
          <span class="notranslate">Financeiro</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Extract") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/wallet/withdrawal" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Withdrawal") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/wallet/withdrawal/history" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Withdrawal History") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z">
            </path>
          </svg>
          <span class="notranslate">Rede</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Sponsored") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/team" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Team") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M4 21h17v-2H5V3H3v17a1 1 0 0 0 1 1z"></path>
            <circle cx="10" cy="8" r="2"></circle>
            <circle cx="18" cy="12" r="2"></circle>
            <circle cx="11.5" cy="13.5" r="1.5"></circle>
            <circle cx="16.5" cy="6.5" r="1.5"></circle>
          </svg>
          <span class="notranslate">Relatórios</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/reports/referral" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Indicação") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z">
            </path>
          </svg>
          <span class="notranslate">Suporte</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/faq" class="side-nav-link-ref">
              <span class="notranslate">{{ t("FAQ") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/tickets" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Atendimento") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
